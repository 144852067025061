import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  Box,
  Container,
  makeStyles,
  Paper,
  Typography,
  Grid,
  Card,
  IconButton,
} from '@material-ui/core';
import Page from 'src/components/Page';
import Results from './LogsListResults';
import EmailList from './EmailList';
import SessionList from './SessionList';
import DocumentationList from './DocumentationList';
import Toolbar from './Toolbar';
import PcsCamera from '../verify-respond/PcsCamera';
import DataStream from '../../components/DataStream';
import {
  useGetMediatorStreamingUrl,
  useGetPcsEventQuery,
  useGetVideoToken,
} from '../../hooks/eventHooks';
import DenseCardHeader from '../../components/DenseCardHeader';
import { useMostRecent } from '../../utils/useMostRecent';
import { useVideoPlayer } from 'src/components/VideoPlayer/useVideoPlayer';
import dayjs from 'dayjs';
import NotificationsList from './NotificationsList';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import { assignCategoryOfDetectedEvents } from 'src/components/DataStream/helpers';
import { Navigate } from 'react-router';
import { Role } from 'src/models';
import { useGetRepMe } from 'src/hooks/repHooks';
import HlsPlayer from 'src/components/VideoPlayer/HlsPlayer';
import { LoadingSpinner } from 'src/components/LoadingSpinner';
import { useUserCanAccessAuditRecordings } from 'src/hooks/recordingHook';
import { Action } from 'src/models';
import {
  useLocationFeatureEnabled,
  LocationFeatures,
} from 'src/hooks/locationHooks';

const OLDEST_ALLOWED_TIMESTAMP = 30 * 24 * 3600; // 30 days

const allowableEventDispositions = new Set([
  Action.canceled_rep,
  Action.agent_handled,
  Action.canceled_by_customer,
  Action.dispatched_police,
  Action.dispatched_fire,
  Action.dispatched_medical,
  Action.dispatched_potential_fire,
  Action.dispatched_suspicious_activity,
]);

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

const RestrictedTile = () => {
  return (
    <Card>
      <Box
        height="50vh"
        position="relative"
        flex={1}
        display="flex"
        justifyContent="center"
        alignItems="center"
        fontSize={18}
      >
        You do not have permission to access this item
      </Box>
    </Card>
  );
};

const LogsListView = () => {
  const recentEvent = useMostRecent();
  const classes = useStyles();
  const [searchValue, setSearchValue] = useState('');
  const [expanded, setExpanded] = useState(false);
  const [searchQueriesForAuditList, setSearchQueriesForAuditList] = useState(
    {}
  );

  const eventQuery = useGetPcsEventQuery(searchQueriesForAuditList?.event_id);

  const videoToken = useGetVideoToken(
    searchQueriesForAuditList?.secondaryEvent?.sid,
    searchQueriesForAuditList?.secondaryEvent?.eventTimestamp,
    searchQueriesForAuditList?.secondaryEvent?.pcsMetadata
  );

  const { data: repData } = useGetRepMe();
  const userCanAccessRecordings = useUserCanAccessAuditRecordings();
  const qaReviewEnabled = useLocationFeatureEnabled({
    sid: recentEvent.sid,
    feature: LocationFeatures.QA_OPERATIONAL_REVIEW,
  });

  const initialPlayerState = { isPlaying: true, volume: 0 };

  const playerState = useVideoPlayer(recentEvent.userId, initialPlayerState);

  const handleChangeDate = (date, name) => {
    setSearchQueriesForAuditList({
      ...searchQueriesForAuditList,
      [name]: date,
    });
  };

  useEffect(() => {
    setSearchQueriesForAuditList({
      ...searchQueriesForAuditList,
      event_id: recentEvent.eventId,
      sid: recentEvent.sid,
      user_id: recentEvent.userId,
    });
  }, [recentEvent.eventId, recentEvent.userId, recentEvent.sid]);

  const [filtersError, setFiltersError] = useState({
    edit_uid: false,
    user_id: false,
    sid: false,
    event_id: false,
  });

  const handleChangeFilterInfo = (event) => {
    const target = event.target;
    setFiltersError({
      ...filtersError,
      [target.name]: false,
    });
    const exceptions = ['type'];
    const checkExceptions = exceptions.includes(target.name);
    if (checkExceptions) {
      setSearchQueriesForAuditList({
        ...searchQueriesForAuditList,
        [target.name]: target.value,
      });
    } else {
      !isNaN(+target.value) && !target.value.includes(' ')
        ? setSearchQueriesForAuditList({
            ...searchQueriesForAuditList,
            [target.name]: target.value,
          })
        : setFiltersError({
            ...filtersError,
            [target.name]: true,
          });
    }
  };

  const onStreamClickHandler = (item) => {
    if (item !== 0) {
      document.querySelector('video').currentTime = item.pts_seconds;
    }
  };

  const detections = useCallback(assignCategoryOfDetectedEvents(eventQuery), [
    eventQuery,
  ]);

  const hasMinDetectionsToExpand = detections && detections.length > 2;

  const secondaryEvent = searchQueriesForAuditList.secondaryEvent;
  const videoURL =
    secondaryEvent?.video?.[Object.keys(secondaryEvent.video)[0]]?._links[
      'playback/hls'
    ];

  const { data: streamingUrl } = useGetMediatorStreamingUrl(
    videoURL?.href,
    videoToken
  );

  if (repData?.role === Role.AGENT_OPS) return <Navigate to="/app/dashboard" />;

  const elapsed = Date.now() / 1000 - eventQuery.data?.eventTimestamp;

  const dispostitionAllowed = allowableEventDispositions.has(
    eventQuery.data?.disposition?.action
  );
  const allowRecordingAccess =
    dispostitionAllowed &&
    userCanAccessRecordings &&
    qaReviewEnabled &&
    elapsed <= OLDEST_ALLOWED_TIMESTAMP;

  return (
    <Page className={classes.root} title="Audit List">
      <Container maxWidth={false}>
        <Typography variant="h3" color="textPrimary">
          Audit List
        </Typography>
        <Toolbar
          filtersError={filtersError}
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          searchQueriesForAuditList={searchQueriesForAuditList}
          setSearchQueriesForAuditList={setSearchQueriesForAuditList}
          handleChangeFilterInfo={handleChangeFilterInfo}
          handleChangeDate={handleChangeDate}
          eventQuery={eventQuery}
        />
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Card>
              <Box height="50vh" position="relative" className={'fs-exclude'}>
                {allowRecordingAccess && (
                  <>
                    {secondaryEvent && secondaryEvent.pcsMetadata ? (
                      <PcsCamera
                        key={secondaryEvent.eventId}
                        eventId={secondaryEvent.eventId}
                        playerState={{ ...playerState }}
                        liveIndicator={false}
                        eventQuery={{
                          data: secondaryEvent.pcsMetadata,
                          isSuccess: true,
                        }}
                      />
                    ) : streamingUrl ? (
                      <>
                        {!videoToken ? (
                          <LoadingSpinner />
                        ) : (
                          <HlsPlayer
                            key={streamingUrl}
                            authHeader={videoToken}
                            url={streamingUrl}
                            onConnectionState={() => {}}
                            className="aspectRatio"
                            controls
                            style={{ width: '100%', height: '100%' }}
                          />
                        )}
                      </>
                    ) : (
                      searchQueriesForAuditList?.event_id && (
                        <PcsCamera
                          key={searchQueriesForAuditList?.event_id}
                          eventId={searchQueriesForAuditList?.event_id}
                          playerState={{ ...playerState }}
                          liveIndicator={false}
                          eventQuery={eventQuery}
                        />
                      )
                    )}
                  </>
                )}

                {!allowRecordingAccess && <RestrictedTile />}
              </Box>
            </Card>
          </Grid>

          {allowRecordingAccess ? (
            <>
              <Grid item xs={6}>
                <Card
                  style={{
                    height: expanded ? '100%' : 'auto',
                    maxHeight: expanded ? '100%' : '50vh',
                  }}
                >
                  <Box
                    style={{
                      height: '100%',
                      maxHeight: expanded ? '100%' : '50vh',
                    }}
                  >
                    <DenseCardHeader
                      title="Datastream"
                      second={
                        eventQuery.data?.eventTimestamp
                          ? dayjs(eventQuery.data.eventTimestamp * 1000).format(
                              'MMM D, H:mm a'
                            )
                          : undefined
                      }
                    />
                    <DataStream
                      eventId={searchQueriesForAuditList?.event_id}
                      isSelectingPeople={true}
                      onItemClick={onStreamClickHandler}
                      expanded={expanded}
                      hasMinDetectionsToExpand={hasMinDetectionsToExpand}
                      showCluster
                      eventQuery={eventQuery}
                    />
                  </Box>
                </Card>
              </Grid>
              {hasMinDetectionsToExpand ? (
                <Container
                  key="expand"
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexGrow: 0,
                    maxWidth: '50%',
                    flexBasis: '50%',
                    marginLeft: '50%',
                  }}
                >
                  <IconButton
                    onClick={() => setExpanded(!expanded)}
                    aria-label={expanded ? 'hide' : 'show more'}
                    size="small"
                  >
                    {expanded ? <ExpandLess /> : <ExpandMore />}
                  </IconButton>
                </Container>
              ) : null}
            </>
          ) : (
            <Grid item xs={6}>
              <RestrictedTile />
            </Grid>
          )}
        </Grid>
        <Paper>
          <Box mt={3} p={1}>
            <Typography variant="h3">Email(s)</Typography>
            {searchQueriesForAuditList?.event_id && (
              <EmailList eventId={searchQueriesForAuditList?.event_id} />
            )}
            <Typography variant="h3">Notification(s)</Typography>
            {searchQueriesForAuditList?.event_id && (
              <NotificationsList
                eventId={searchQueriesForAuditList?.event_id}
              />
            )}
          </Box>
        </Paper>
        <Paper>
          <Box mt={3} p={1}>
            <Typography variant="h3">Session(s)</Typography>
            <SessionList eventId={searchQueriesForAuditList?.event_id} />
          </Box>
        </Paper>
        <Paper>
          <Box mt={3} p={1}>
            <Typography variant="h3">Documentation</Typography>
            <DocumentationList eventId={searchQueriesForAuditList?.event_id} />
          </Box>
        </Paper>
        <Paper>
          <Box mt={3} p={1}>
            <Results
              searchValue={searchValue}
              filterValues={searchQueriesForAuditList}
            />
          </Box>
        </Paper>
      </Container>
    </Page>
  );
};

export default LogsListView;
