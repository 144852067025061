import React, { useEffect, useRef, useState } from 'react';
import { ReadyState } from 'react-use-websocket';
import {
  AudioSocketState,
  playSirenSocket,
  useStreamRecorder,
} from './kvs-camera/useStreamRecorder';
import { AudioAccessDenied, useAudioAccessDenied } from './AudioAccessDenied';
import { useOnValueChange } from '../../utils/useOnValueChange';
import { useGetUserSettings } from 'src/hooks/settingsHooks';
import { useSubscription } from 'src/hooks/useSubscription';
import { useActivateSiren } from 'src/hooks/cameraHooks';

type Props = {
  setWsState: (state: AudioSocketState) => void;
  onStart: () => void;
  onEnd: () => void;
  authHeader: string;
  isTalking: boolean;
  isSiren: boolean;
  sid: number;
  componentRef?: React.MutableRefObject<HTMLVideoElement>;
  uuid: string;
};

async function getFLVTokenUrl(uuid: string) {
  let token = sessionStorage.getItem(`token-${uuid}`);
  const url = `${
    process.env.REACT_APP_2WAY_AUDIO_PROXY
  }?access_token=${encodeURIComponent(token)}&uuid=${encodeURIComponent(uuid)}`;
  return url;
}

const TalkSocket = React.memo<Props>(
  ({ setWsState, authHeader, isTalking, isSiren, uuid }) => {
    const talkingSocketOpen = useRef(false);
    const [socketUrl, setSocketUrl] = useState('');
    const { readyState, error, playSiren } = useStreamRecorder(socketUrl);
    const audioAccessDeniedState = useAudioAccessDenied();

    const { data: userSettings } = useGetUserSettings();
    const { mic_volume: volume } = userSettings ?? { mic_volume: 3 };

    useEffect(() => {
      if (error) {
        audioAccessDeniedState.open();
      }
    }, [error]);

    const connectionStatus = {
      [ReadyState.CONNECTING]: '#fbff00',
      [ReadyState.OPEN]: '#2bff00',
      [ReadyState.CLOSING]: '#ff9900',
      [ReadyState.CLOSED]: '#ff9900',
      [ReadyState.UNINSTANTIATED]: '#ff0000',
    }[readyState];

    useEffect(() => {
      setWsState(readyState);
    }, [readyState]);

    useOnValueChange(isTalking, async (value) => {
      const url = await getFLVTokenUrl(uuid);
      if (value) {
        setSocketUrl(url);
      } else {
        setSocketUrl(null);
      }
    });

    useOnValueChange(isSiren, async (value) => {
      if (value) {
        if (readyState === AudioSocketState.OPEN) {
          await playSiren();
        } else {
          const url = await getFLVTokenUrl(uuid);
          await playSirenSocket(url, volume);
        }
      }
    });

    useEffect(() => {
      if (
        readyState === AudioSocketState.CONNECTING &&
        !talkingSocketOpen.current
      ) {
        talkingSocketOpen.current = true;
      } else if (
        (readyState === AudioSocketState.CLOSED ||
          readyState === AudioSocketState.UNINSTANTIATED) &&
        talkingSocketOpen.current
      ) {
        talkingSocketOpen.current = false;
      }
    }, [readyState]);

    // Colored line to indicate socket status
    const style = {
      width: '15px',
      height: '0',
      position: 'absolute',
      right: '1vw',
      bottom: '0px',
      borderWidth: '3px',
      borderStyle: 'solid',
      borderRadius: '4px',
      borderColor: connectionStatus,
    } as React.CSSProperties;

    if (isTalking)
      return (
        <>
          <AudioAccessDenied {...audioAccessDeniedState.props} />
          <span style={style} />
        </>
      );
    return null;
  }
);

export default TalkSocket;
